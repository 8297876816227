import React from "react"
import SEO from "../components/main/seo"
import Layout from "../containers/layout"
import { responsiveTitle1 } from "../styles/typography.module.css"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <h1 className={responsiveTitle1}>Seite nicht gefunden</h1>
    <p>
      Das bereitet uns schlaflose Nächte... Oder du hast dich vielleicht im Sekundenschlaf vertippt?
    </p>
  </Layout>
)

export default NotFoundPage
